<template>
    <div class="musicplayer">
        <div class="bg">
            <div class="picbox" v-if="music.imgUrl">
                <div class="pic" :style="{ backgroundImage: 'url(' + baseUrl + music.imgUrl + ')' }"></div>
            </div>
        </div>
        <div class="mainbox">
            <div class="music">
                <div class="music-title">
                    <div class="atitle">{{ music.title }}</div>
                    <div class="btitle">{{ music.author }}</div>
                    <div class="icon-btn">
                        <div class="picbox" @click="menuOn">
                            <div class="pic" style="background-image: url(/img/menu.png)"></div>
                        </div>
                        <div class="picbox" @click="musicClose" v-show="menuOffShow">
                            <div class="pic" style="background-image: url(/img/power.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="poster" v-bind:class="{ 'rotate': play }">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/poster.png)"></div>
                    </div>
                </div>
            </div>
            <div class="playbar">
                <div class="control">
                    <div class="prev">
                        <div class="picbox" @click="musicPrev()">
                            <div class="pic" style="background-image: url(/img/prev.png)"></div>
                        </div>
                    </div>
                    <div class="toggle" @click="togglePlay">
                        <div class="pause" v-if="play">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/pause.png)"></div>
                            </div>
                        </div>
                        <div class="play" v-else>
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/play.png)"></div>
                            </div>
                        </div>
                    </div>
                    <div class="next">
                        <div class="picbox" @click="musicNext()">
                            <div class="pic" style="background-image: url(/img/next.png)"></div>
                        </div>
                    </div>
                    <div class="volumn">
                        <div>
                            <input type="range" min="0" max="100" v-model="volume" @input="setVolume" />
                        </div>
                    </div>
                </div>
                <div class="time">
                    <div class="play-time">{{ playTime }}</div>
                    <div class="process-bar">
                        <div class="process"
                            :style="{ width: audio.duration > 0 ? audio.currentTime * 100 / audio.duration + '%' : 0 }">
                            <div class="round"></div>
                        </div>
                    </div>
                    <div class="total-time">{{ totalTime }}</div>
                </div>
            </div>
        </div>
        <div class="play-menu" :class="{ active: menuShow }">
            <div class="shadow" @click="menuOff"></div>
            <div class="menu">
                <!-- 音乐分类 -->
                <div class="music-cate" v-if="isCate">
                    <div class="cate-top">
                        <div class="titlebox">
                            <div class="atitle">音乐分类</div>
                            <div class="close" @click="menuOff">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/close.png)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="taglist">
                            <div class="tag" v-for="(item, index) in tags" :key="index"
                                @click="cateChan(item.id, false, item.title)">
                                <div class="picbox">
                                    <div class="pic" :style="{ backgroundImage: 'url(' + baseUrl + item.imgUrl + ')' }">
                                    </div>
                                </div>
                                <div class="txt">{{ item.title }}</div>
                            </div>
                        </div>

                    </div>
                    <div class="cate-bottom">
                        <div class="logo">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/logo_icon.png)"></div>
                            </div>
                        </div>
                        <div class="qrcode">
                            <div class="picbox">
                                <div ref="qrCode"></div>
                            </div>
                        </div>
                        <div class="txt">
                            <div class="t1">扫码<br>继续</div>
                        </div>
                    </div>
                </div>
                <!-- 音乐列表 -->
                <div class="music-select" v-else>
                    <div class="select-top">
                        <div class="titlebox">
                            <div class="atitle">{{ cateName }}</div>
                            <div class="back" @click="backCate()">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/back.png)"></div>
                                </div>
                            </div>
                            <div class="close" @click="menuOff">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/close.png)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="select-bottom">
                        <div class="musiclist">
                            <div class="music" v-for="(item, index) in musicList" :key="index" @click="getMusic(item);musicPlay();">
                                <div class="icon">
                                    <div class="picbox">
                                        <div class="pic" style="background-image: url(/img/music_on.png)"
                                            v-if="item.id === music.id"></div>
                                        <div class="pic" style="background-image: url(/img/music_off.png)" v-else></div>
                                    </div>
                                </div>
                                <div class="music-title" :class="{ play: item.id==music.id }">
                                    <div class="music-name">{{ item.title }}</div>
                                    <div class="singer">{{ item.author }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="empty" style="width: 100%; text-align: center; font-size: 28px; color: #fff"
                            v-if="message">{{ message }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { getBase } from "@/js/request";
import { getMusicInfo } from "@/js/request";
import { getCategory } from "@/js/request";
import { getMusicCate } from "@/js/request";
import QRCode from 'qrcode';
import { isPhone } from "../utils/tool"

export default {
    name: 'MusicPlayer',
    components: {

    },
    data() {
        return {
            // 页面信息
            base: {},
            baseUrl: '',
            // 音乐信息
            music: {},
            musicList: [],
            play: false,
            menuShow: true,
            // 进度条信息
            position: 0,
            processBarLeft: 0,
            processBarWidth: 0,
            // 分类列表
            tags: [],
            isCate: true,
            cateName: '',
            // 音乐流
            newAudio: null,
            audio: {
                duration: 0,
                currentTime: 0,
            },
            duration:0,//总时长
            // 播放时间读取
            currentTime: 0,
            playInterval: null,
            // 读取音乐列表
            page: 1,
            cateId: 0,
            message: '',
            menuOffShow: true,
            volume: 50,
        }
    },
    computed: {
        totalTime() {
            let m = Math.floor(this.duration / 60);
            if (m < 10) {
                m = "0" + m.toString();
            }
            let s = Math.floor(this.duration % 60);
            if (s < 10) {
                s = "0" + s.toString();
            }
            return m + ":" + s;
        },
        playTime() {
            let m = Math.floor(this.currentTime / 60);
            if (m < 10) {
                m = "0" + m.toString();
            }
            let s = Math.floor(this.currentTime % 60);
            if (s < 10) {
                s = "0" + s.toString();
            }
            return m + ":" + s;
        }
    },
    inject: ['musicOn','musicOff'],
    mounted() {
        if(isPhone()){
            this.menuOffShow = false;
        }
        this.$refs.qrCode.innerHTML = '';
        QRCode.toCanvas(window.location.href, { errorCorrectionLevel: 'H', width: 80 }, (err, canvas) => {
            if (err) throw error
            this.$refs.qrCode.appendChild(canvas)
        });
        this.$root.hideRight();
        let audio = new Audio();       
        this.newAudio = audio;
        if (this.$route.query.id) {
            getMusicInfo(this.$route.query.id).then(res => {
                this.cateId = res.data.articleCategoryRelations[0].categoryId;
                this.baseUrl = res.config.baseURL;
                if (res.data.fields !== undefined) {
                    this.getMusic(res.data);
                }
            });
        }
        
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
        getCategory(3).then(res => {
            this.tags = res.data;
        });
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({ path: adress, query: cate });
        },
        menuOn() {
            this.menuShow = true;
            // if (this.isCate) this.$router.push('/musicMenu/musicCate');
            // else this.$router.push('/musicMenu/musicSelect')
        },
        menuOff() {
            this.menuShow = false;
        },
        backCate() {
            this.isCate = true;
        },
        togglePlay() {
            if (this.play) this.musicPause();
            else this.musicPlay();
        },
        musicPlay() {
            var that=this;
            this.play = true;
            this.audio.play();
            this.musicOn();
            setTimeout(function(){
                that.duration = that.audio.duration;
            },1000);
            this.playInterval = setInterval(() => {
                this.currentTime = this.audio.currentTime;
                if(this.audio.ended){
                    this.duration = 0;
                    that.musicNext();  
                }
            }, 1000);
        },
        musicPause() {
            this.play = false;
            this.audio.pause();
            clearInterval(this.playInterval);
            this.musicOff();
        },
        musicClose() {
            this.musicOff();
            this.audio.pause();
            this.goto('/');
        },
        getMusic(music) {
            this.music = music;
            this.newAudio.src = this.music.fields.music;
            this.load(this.newAudio);
            getMusicCate(this.page, this.cateId, '').then(res => {
                this.message = '';
                this.musicList = res.data;
            }).catch(error => {
                if (error.response) {
                    this.musicList = [];
                    this.message = error.response.data.message;
                }
            });
        },
        move(e) {
            if (e.targetTouches) {
                e = e.targetTouches[0];
            }
            this.position = e.pageX - this.processBarLeft;
            if (this.position >= this.processBarWidth) {
                this.position = this.processBarWidth;
            } else if (this.position <= 0) {
                this.position = 0;
            }
            this.currentTime = this.audio.currentTime = this.audio.duration * this.position / this.processBarWidth;
        },
        musicNext() {
            var that = this;
            var now=0;
            that.musicList.forEach(function (item, index) {
                if(item.id==that.music.id){
                    now=index;                    
                    return;
                }            
            });          
            if(now<that.musicList.length-1){
                that.getMusic(that.musicList[now+1]);
            }else{
                that.getMusic(that.musicList[0]);
            }
            that.musicPlay();
        },
        musicPrev() {
            var that = this;
            var now=0;
            that.musicList.forEach(function (item, index) {
                if(item.id==that.music.id){
                    now=index;                    
                    return;
                }            
            });          
            if(now==0){
                that.getMusic(that.musicList[that.musicList.length-1]);
            }else{
                that.getMusic(that.musicList[now-1]);
            }
            that.musicPlay();
        },
        load(audio) {
            if (audio.readyState >= 2) {
                this.audio = audio;
                let processBar = document.querySelector(".process-bar");
                let handle = (e) => this.move(e);
                this.processBarLeft = processBar.offsetLeft;
                this.processBarWidth = processBar.offsetWidth;
                processBar.addEventListener("mousedown", (e) => {
                    if (this.audio.duration > 0) {
                        this.move(e);
                        window.addEventListener("mousemove", handle);
                    }
                });
                window.addEventListener("mouseup", () => {
                    if (this.audio.duration > 0) {
                        window.removeEventListener("mousemove", handle);
                    }
                });
                processBar.addEventListener("touchstart", (e) => {
                    if (this.audio.duration > 0) {
                        this.move(e);
                        window.addEventListener("touchmove", handle);
                    }
                });
                window.addEventListener("touchend", () => {
                    if (this.audio.duration > 0) {
                        window.removeEventListener("touchmove", handle);
                    }
                });
            } else {
                setTimeout(() => {
                    this.load(audio);
                }, 500);
            }
        },
        //类别加载音乐
        cateChan(id, isCate, cateName) {
            this.musicList = [];
            this.isCate = isCate;
            this.cateName = cateName;
            if (id !== 0) {
                this.cateId = id;
                getMusicCate(this.page, this.cateId, '').then(res => {
                    this.message = '';
                    setTimeout(() => {
                        this.musicList = res.data;
                        console.log(this.musicList)
                    }, 500);
                }).catch(error => {
                    if (error.response) {
                        this.musicList = [];
                        this.message = error.response.data.message;
                    }
                });
            }
        },
        setVolume() {
            const normalizedVolume = this.volume / 100;
            this.audio.volume = normalizedVolume;
        },
    }
}
</script>
  
<style lang="scss">
.musicplayer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 99;

    .bg {
        position: absolute;
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
        background: #fff;
        @include sys-picbox(100%, 100%, cover);

        .picbox {
            background: #000;
            filter: blur(10px);

            .pic {
                opacity: .999;
            }
        }
    }

    .mainbox {
        @include sys-flex();
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 5;

        .music {
            @include sys-flex();
            width: 1400px;
            flex: 1;

            .music-title {
                width: 100%;
                position: relative;
                padding: 100px 200px;
                box-sizing: border-box;
                text-align: center;
                flex: none;

                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #fff, bold);
                }

                .btitle {
                    @include sys-text-hide(1);
                    @include sys-font(20px, #999);
                }

                .icon-btn {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    @include sys-picbox(40px, 40px);

                    .picbox {
                        margin-left: 40px;
                        cursor: pointer;
                    }
                }
            }

            .poster {
                flex: 1;
                margin-bottom: 100px;
                @include sys-flex();
                @include sys-picbox(360px, 360px, cover);
                -webkit-animation: rotating 5s linear infinite;
                -moz-animation: rotating 5s linear infinite;
                -o-animation: rotating 5s linear infinite;
                animation: rotating 5s linear infinite;
                animation-play-state:paused;
                .picbox {
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }

        .playbar {
            @include sys-flex();
            width: 100%;
            height: 316px;
            background: rgba(0, 0, 0, .3);
            border-top: 2px solid #666;

            .control {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                .prev,
                .next {
                    @include sys-picbox(22px, 22px);
                    cursor: pointer;
                }

                .toggle {
                    @include sys-radius-btn(60px, 60px, rgba(255, 255, 255, .2));
                    margin: 0 60px;
                    cursor: pointer;

                    .play {
                        @include sys-picbox(19px, 22px);
                    }

                    .pause {
                        @include sys-picbox(16px, 20px);
                    }
                }
                .volumn {
                    margin: 0 60px;
                    cursor: pointer;
                }
            }

            .time {
                display: flex;
                align-items: center;

                .play-time,
                .total-time {
                    @include sys-font(20px, #fff);
                    line-height: 22px;
                }

                .process-bar {
                    @include sys-boxsize(1180px, 6px, 3px, #666);
                    margin: 0 60px;
                    cursor: pointer;

                    .process {
                        max-width: 100%;
                        height: 100%;
                        border-radius: 3px;
                        background: $acolor;
                        position: relative;

                        .round {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #F9F9F9;
                            position: absolute;
                            top: 50%;
                            right: -5px;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }

    .play-menu {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        transition: all .4s;

        &.active {
            opacity: 1;
            visibility: visible;

            .menu {
                right: 0;
            }
        }

        .shadow {
            width: 100%;
            height: 100%;
        }

        .menu {
            width: 530px;
            height: 100%;
            background: #000;
            position: absolute;
            right: -550px;
            top: 0;
            z-index: 5;
            transition: right .4s;
        }
    }
}

.music-cate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 100px 60px;
    box-sizing: border-box;

    .cate-top {
        width: 100%;

        .titlebox {
            @include sys-flex(space-between, row);
            width: 100%;
            padding-top: 10px;
            margin-bottom: 40px;

            .atitle {
                @include sys-text-hide(1);
                @include sys-font(36px, #fff, bold);
                line-height: 44px;
                flex: 1;
            }

            .close {
                @include sys-radius-btn(40px, 40px, rgba(255, 255, 255, .2));
                @include sys-picbox(16px, 16px);
                cursor: pointer;
            }
        }

        .taglist {
            @include sys-flex(space-between, row);
            flex-wrap: wrap;

            .tag {
                @include sys-flex(center, row);
                @include sys-boxsize(200px, 70px, 10px, rgba(255, 255, 255, .1));
                @include sys-picbox(28px, 28px);
                margin-bottom: 10px;
                cursor: pointer;

                .txt {
                    @include sys-text-hide(1);
                    @include sys-font(20px, #fff);
                    margin-left: 10px;
                }
            }
        }
    }

    .cate-bottom {
        @include sys-boxsize(410px, 130px, 10px, $acolor);
        flex: none;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .logo {
            padding-right: 20px;
            margin-right: 20px;
            @include sys-picbox(90px, 90px);
            border-right: 2px solid #fff;
        }

        .qr-c{
            text-align:center;

            div{
                text-align: center;
            }

        }
        .qrcode {
            padding: 6px;
            background-color: #fff;
            @include sys-picbox(84px, 84px);
        }

        .txt {
            margin-left: 40px;
            line-height: 40px;

            .t1 {
                @include sys-text-hide(2);
                @include sys-font(30px, #fff, bold);
                margin-bottom: 5px;
            }

            .t2 {
                @include sys-text-hide(2);
                @include sys-font(16px, #fff);
            }
        }
    }
}

.music-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 100px 60px;
    box-sizing: border-box;

    .select-top {
        width: 100%;
        flex: none;

        .titlebox {
            @include sys-flex(space-between, row);
            width: 100%;
            padding-top: 10px;
            margin-bottom: 40px;

            .atitle {
                @include sys-text-hide(1);
                @include sys-font(36px, #fff, bold);
                line-height: 44px;
                flex: 1;
            }

            .back,
            .close {
                @include sys-radius-btn(40px, 40px, rgba(255, 255, 255, .2));
                cursor: pointer;
                margin-left: 30px;
                flex: none;
            }

            .back {
                @include sys-picbox(17px, 18px);
            }

            .close {
                @include sys-picbox(16px, 16px);
            }
        }
    }

    .select-bottom {
        flex: 1;
        overflow-y: auto !important;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: #666;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-resizer {
            background: #666;
        }

        &::-webkit-scrollbar-track-piece {
            background: #333;
            border-radius: 2px;
        }

        .musiclist {
            padding: 0 30px 0 0;

            .music {
                display: flex;
                align-items: center;
                border-bottom: 2px solid #333;
                padding-bottom: 20px;
                margin-bottom: 20px;
                cursor: pointer;

                &:last-child {
                    border-bottom: none;
                }

                .icon {
                    @include sys-picbox(42px, 42px);
                    margin-right: 20px;
                    flex: none;
                }

                .music-title {
                    color: #999;

                    &.play {
                        color: #fff;
                    }

                    .music-name {
                        @include sys-text-hide(1);
                        font-size: 20px;
                    }

                    .singer {
                        @include sys-text-hide(1);
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.rotate {
    animation-play-state: running !important;
}

@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0) }
    to { -webkit-transform: rotate(360deg) }
}

@keyframes rotating {
    from { transform: rotate(0) }
    to { transform: rotate(360deg) }
}
@-moz-keyframes rotating {
    from { -moz-transform: rotate(0) }
    to { -moz-transform: rotate(360deg) }
}
</style>